<script>
import TablePage from '@/found/components/table_page';
import Form from '../components/detail';

export default {
  name: 'quarter_data_list',
  extends: TablePage,
  data() {
    return {
      // requestType: 'GET',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      searchFormData: {},
      isCalculateHeight: false,
    };
  },
  components: {
    Form,
  },
  methods: {
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      this.$emit('newData', this.formData);
      if ($event.type === 'reset') {
        this.$emit('newData', {});
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formName = 'Form';
        this.modalConfig.title = '详情';
        this.modalConfig.width = '80%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.formConfig = {
          ...this.formConfig,
          row,
          code: val.code,
        };
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('quarter_data_list');
  },
};
</script>
