import Form, { formCreate } from '@/found/components/form';

import Header from './components/header.vue';
import Table from './table';

formCreate.component('Header', Header);
formCreate.component('Table', Table);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'quarterDataForm',
    };
  },
  methods: {
    setRule(item) {
      const val = item;
      console.log(val, 'val');
      if (val.field === 'table') {
        val.on = {
          ...val.on,
          newData: (data) => {
            console.log(data, 1111);
            const header = this.getRule('header');
            header.props = {
              ...header.props,
              searchFormData: data,
            };
          },
        };
      }
      return val;
    },
    formComplete() {
    },
  },
  watch: {},
};
