var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-box1" }, [
    _c("div", { staticClass: "zhixing" }, [
      _c("span", { staticClass: "test" }, [_vm._v("总投入费用（元）")]),
      _c("span", { staticClass: "money" }, [
        _vm._v(_vm._s(_vm.titleSumAmount)),
      ]),
    ]),
    _c("div", { staticClass: "dacheng" }, [
      _c("span", { staticClass: "test" }, [_vm._v("涉及终端数量（家）")]),
      _c("span", { staticClass: "money" }, [
        _vm._v(_vm._s(_vm.titleCountTerminalNum)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }