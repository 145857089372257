<template>
  <div class="header-box1">
    <div class="zhixing">
      <span class="test">总投入费用（元）</span>
      <span class="money">{{ titleSumAmount }}</span>
    </div>
    <div class="dacheng">
      <span class="test">涉及终端数量（家）</span>
      <span class="money">{{ titleCountTerminalNum }}</span>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      titleSumAmount: null,
      titleCountTerminalNum: null,
    };
  },
  props: {
    searchFormData: Object,
  },
  watch: {
    searchFormData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(params) {
      request
        .get(
          '/cps/v1/report/fxhTerminalInterestRecord/findTitleThisQuarterByConditions',
          params,
        )
        .then((res) => {
          if (res.success) {
            const { result } = res;
            this.titleSumAmount = result.titleSumAmount;
            this.titleCountTerminalNum = result.titleCountTerminalNum;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.header-box1 {
  width: 100%;
  height: 216px;
  display: flex;
  align-items: center;
  div {
    margin-right: 24px;
    width: 20%;
    height: 168px;
    font-family: PingFangSC-Regular;
    color: #333333;
    border-radius: 4px;
    // background-image: linear-gradient(-45deg, #EEF6FF 0%, #FFFFFF 100%);
    box-shadow: 0px 0px 10px 0px rgba(14, 93, 195, 0.1);

    span {
      display: block;
      font-size: 16px;
    }
    .test {
      height: 24px;
      line-height: 24px;
      margin: 30px 0 12px 20px;
    }
    .money {
      height: 58px;
      line-height: 58px;
      font-size: 50px;
      color: #0e5dc3;
      font-weight: 500;
      margin-left: 20px;
    }
  }
  .zhixing {
    background: url("~@/found/assets/img/cpsimg/zhixing.png") no-repeat;
    background-size: cover;
  }
  .dacheng {
    background: url("~@/found/assets/img/cpsimg/dacheng.png") no-repeat;
    background-size: cover;
    .money {
      color: #f79435;
    }
  }
  .jifen {
    background: url("~@/found/assets/img/cpsimg/jifen.png") no-repeat;
    background-size: cover;
    .money {
      color: #28b795;
    }
  }
  .hongbao {
    background: url("~@/found/assets/img/cpsimg/hongbao.png") no-repeat;
    background-size: cover;
  }
  .feiyong {
    background: url("~@/found/assets/img/cpsimg/feiyong.png") no-repeat;
    background-size: cover;
    .money {
      color: #f79435;
    }
    margin-right: 0;
  }
}
.header-box {
}
</style>
