// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/found/assets/img/cpsimg/zhixing.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/found/assets/img/cpsimg/dacheng.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/found/assets/img/cpsimg/jifen.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/found/assets/img/cpsimg/hongbao.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/found/assets/img/cpsimg/feiyong.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".header-box1[data-v-001659a5] {\n  width: 100%;\n  height: 216px;\n  display: flex;\n  align-items: center;\n}\n.header-box1 div[data-v-001659a5] {\n  margin-right: 24px;\n  width: 20%;\n  height: 168px;\n  font-family: PingFangSC-Regular;\n  color: #333333;\n  border-radius: 4px;\n  box-shadow: 0px 0px 10px 0px rgba(14, 93, 195, 0.1);\n}\n.header-box1 div span[data-v-001659a5] {\n  display: block;\n  font-size: 16px;\n}\n.header-box1 div .test[data-v-001659a5] {\n  height: 24px;\n  line-height: 24px;\n  margin: 30px 0 12px 20px;\n}\n.header-box1 div .money[data-v-001659a5] {\n  height: 58px;\n  line-height: 58px;\n  font-size: 50px;\n  color: #0e5dc3;\n  font-weight: 500;\n  margin-left: 20px;\n}\n.header-box1 .zhixing[data-v-001659a5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: cover;\n}\n.header-box1 .dacheng[data-v-001659a5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n  background-size: cover;\n}\n.header-box1 .dacheng .money[data-v-001659a5] {\n  color: #f79435;\n}\n.header-box1 .jifen[data-v-001659a5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n  background-size: cover;\n}\n.header-box1 .jifen .money[data-v-001659a5] {\n  color: #28b795;\n}\n.header-box1 .hongbao[data-v-001659a5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat;\n  background-size: cover;\n}\n.header-box1 .feiyong[data-v-001659a5] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat;\n  background-size: cover;\n  margin-right: 0;\n}\n.header-box1 .feiyong .money[data-v-001659a5] {\n  color: #f79435;\n}\n", ""]);
// Exports
module.exports = exports;
