import BasePage from '@/found/components/table_page';
import form from '../../equity_redemption_record/form';

export default {
  name: 'quarterDataDetailList',
  extends: BasePage,
  props: {
    formConfig: Object,
  },
  data() {
    return {
      // requestType: 'GET',
      params: {
        reportType: 2,
      },
    };
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },
  },
  created() {
    console.log(this.formConfig, 'formConfig');
    this.params.terminalCodeComplete = this.formConfig.row.terminalCode;
    this.getConfigList('quarterDataDetailList');
  },
};
